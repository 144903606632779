import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import vSelect from "vue-select";

import moment from 'moment'

import './registerServiceWorker'

import { useFavicon } from '@vueuse/core'
const icon = useFavicon();

icon.value = process.env.VUE_APP_URL_API+"/api/logo";

let hostname  = location.hostname;
let domain    = hostname;
let subdomain = "";

if(domain != "192.168.0.10" && domain != "192.168.0.14" && domain != "localhost" && domain != "192.168.1.20"){
    domain  = location.host.split(".");
    if(domain.length > 3){
        subdomain = domain[0];
    }
}else{
    subdomain = 'nextravel';
    // subdomain = 'portal';
}

Vue.prototype.moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$subdomain = subdomain;

import LayoutHeader from '@/components/partials/TopBar'
import Sidebar from '@/components/partials/SideBar'
import Breadcrumb from '@/components/partials/Breadcrumb'
import TablePagination from '@/components/partials/TablePagination'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'
import './assets/css/animate.min.css'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';

if(hostname.includes('nextravel.com.mx') || hostname == "192.168.0.10" || hostname == "192.168.0.14" || hostname == "localhost"){
import('./assets/css/admin.css')
}else{
import('./assets/css/yellow.css')
}
import('./assets/css/default.css')

import "vue-select/dist/vue-select.css";

import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

var numeral = require("numeral");

import VueEditor from "vue2-editor";

import "vue-step-wizard/dist/vue-step-wizard.css";

import VueNumericInput from 'vue-numeric-input';


Vue.use(VueNumericInput)

Vue.use(VueEditor);
Vue.use(VueSweetalert2);

Vue.prototype.$swalDelete = function name(url, callback) {
    this.$swal({
        title: 'Estas Seguro?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminarlo!',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            return this.$axios
            .delete(`/${this.$subdomain}/${url}`)
            .then(response => {
                if(!response.data.success){
                    throw new Error(response.data.message)
                }
                return response.data;
            })
            .catch(error => {
                this.$swal.showValidationMessage(
                    `${error}`
                )
            })
        },
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed && result.value.success) {
            this.$swal({
                title   : `Eliminado`,
                html    : result.value.message,
                icon    : 'success'
            });

            if (callback) {
                callback();
            }
        }else{
            // this.$swal({
            //     title   : `Hubo un error al intentar eliminar`,
            //     icon    : 'info'
            // });
        }
    });
}

/**
 * 
 * @param {*} title 
 * @param {*} description 
 * @param {*} type  /success - error - info - warning - question
 */
Vue.prototype.$swalResponse = function(title, description, type) {
    this.$swal({
        title               : title,
        html                : description,
        icon                : type,
        confirmButtonText   : 'Ok'
    });
}

//Vue.config.productionTip = false

/*Global Components */
Vue.component('layout-header', LayoutHeader)
Vue.component('layout-sidebar', Sidebar)
Vue.component('breadcrumb-page', Breadcrumb)
Vue.component("v-select", vSelect);
Vue.component("TablePagination", TablePagination);
Vue.component("DatePicker", DatePicker);

Vue.filter(
    "formatNumber", function (value) {
        return numeral(value).format('0,0.00');
    }
);

Vue.filter(
    "date_format_time", function (value) {
        if (!value)
            return '';

        return new Date(value).toLocaleString();
    }
);

Vue.filter(
    "date_format", function (value) {
        if (!value)
            return '';
        return moment(String(value)).format('DD/MM/YYYY')
        //return new Date(value).toLocaleDateString();
    }
);


Vue.prototype.$store = store
Vue.prototype.$baseUrl = process.env.VUE_APP_URL_API;
Vue.prototype.$baseUrlBest = process.env.VUE_APP_URL_BEST_API;

axios.defaults.baseURL = process.env.VUE_APP_URL_API+'/api';

axios.interceptors.response.use(
    (response) => {
        store.commit('loading', false);
        return response;
    },
    error => {
        store.commit('loading', false);
        if (error.response.status === 403) { 
            Swal.fire({
                title   : `Opss.. Sin acceso`,
                html    : 'No tiene acceso para realizar este proceso',
                icon    : 'error'
            });
        }
    }
);

window.Push = require('push.js');

axios.interceptors.request.use(function(config) {
    store.commit('loading', true);
    config.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    return config;
}, (error) => {
    store.commit('loading', false);
    return Promise.reject(error);
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')